import React from 'react'
import { Grid } from '@react-md/utils'
import { Text } from 'react-md'

import { DateLabel, DateTimeLabel, DecimalLabel, NumberLabel, TextLabel } from 'components/label'
import { getPartnerIntegrationDescription } from 'types/order/partner-integration'
import { getFiscalDocumentDescription } from 'types/order/fiscal-document'
import { getTypeDeliveryDescription } from 'types/order/type-delivery'
import { getServiceDescription } from 'types/quote/service'
import { getStatusDescription } from 'types/order/status'
import { getOriginDescription } from 'types/order/origin'
import { Cell } from 'components/core'

interface ComponentProps {
  order: any
}

const Component: React.FC<ComponentProps> = ({ order }) => {
  return (
    <Grid>
      <Cell>
        <Text type='headline-6' margin='none'>
          Informações Operacionais
        </Text>
      </Cell>
      <Cell>
        <Grid>
          <TextLabel name='clientFantasyName' label='Cliente' value={order.clientFantasyName} desktopSize={4} tabletSize={8} />
          <TextLabel name='status' label='Status' value={getStatusDescription(order.status)} desktopSize={3} tabletSize={4} />
          <DateTimeLabel name='createdAt' label='Data do Cadastro' value={order.createdAt} desktopSize={3} tabletSize={4} />
          <DateLabel name='deliveryForecast' label='Previsão de Entrega' value={order.deliveryForecast} desktopSize={2} tabletSize={4} />
          <TextLabel name='service' label='Tipo de Serviço' value={getServiceDescription(order.service)} desktopSize={2} tabletSize={4} />
          <TextLabel
            name='typeDelivery'
            label='Tipo de Entrega'
            value={getTypeDeliveryDescription(order.typeDelivery)}
            desktopSize={2}
            tabletSize={4}
          />
          <TextLabel
            name='fiscalDocument'
            label='Documento Fiscal'
            value={getFiscalDocumentDescription(order.fiscalDocument)}
            desktopSize={2}
            tabletSize={4}
          />
          <NumberLabel name='volumes' label='Volumes' value={order.volumes} desktopSize={1} tabletSize={4} />
          <TextLabel name='origin' label='Origem' value={getOriginDescription(order.origin)} desktopSize={2} tabletSize={4} />
          <TextLabel name='trackingCode' label='Tracking' value={order.trackingCode} desktopSize={3} tabletSize={4} />
          <DecimalLabel name='realWeight' label='Peso Real' value={order.realWeight} desktopSize={2} tabletSize={4} />
          <DecimalLabel name='cubicWeight' label='Peso Cubado' value={order.cubicWeight} desktopSize={2} tabletSize={4} />
          <TextLabel name='cubicDescription' label='Cubagem' value={order.cubicDescription} desktopSize={2} tabletSize={4} />
          <TextLabel name='partner' label='Parceiro' value={getPartnerIntegrationDescription(order.partner)} desktopSize={3} tabletSize={4} />
          {order.awb && <TextLabel name='awb' label='Awb' value={order.awb} desktopSize={3} tabletSize={4} />}
        </Grid>
      </Cell>
    </Grid>
  )
}

export default Component
